import CareerForm from '../components/CareerForm'

const Careers = () => {
    return (
        <>
            <CareerForm />
        </>
    )
}

export default Careers;